import { initializeApp } from 'firebase/app';
import { query, collection, getDocs, getFirestore, doc, updateDoc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import axios from 'axios';
import uniqBy from 'lodash/uniqBy';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

const updateRaffleProps = (raffle) => {
  raffle.ticketCost = parseInt(raffle.ticketCost);
  raffle.assetId = parseInt(raffle.assetId);

  if (raffle.maxEntries) {
    raffle.maxEntries = parseInt(raffle.maxEntries);
  } else {
    delete raffle.maxEntries;
  }

  if (raffle.totalTickets) {
    raffle.totalTickets = parseInt(raffle.totalTickets);
  } else {
    delete raffle.totalTickets;
  }

  if (raffle.startDate) {
    raffle.startDate = new Date(raffle.startDate);
  }

  if (raffle.endDate) {
    raffle.endDate = new Date(raffle.endDate);
  }

  return raffle;
};

export const createRaffle = async (raffle) => {
  // get raffles
  const rafflesRef = collection(db, 'raffles');
  const rafflesQuery = query(rafflesRef);
  const rafflesSnapshot = await getDocs(rafflesQuery);
  const raffles = rafflesSnapshot.docs.map((doc) => doc.data()).sort((a, b) => parseInt(b.id) - parseInt(a.id));
  const [mostRecentRaffle] = raffles;
  const nextId = mostRecentRaffle ? parseInt(mostRecentRaffle.id) + 1 : 1;

  raffle = updateRaffleProps(raffle);

  await setDoc(
    doc(db, 'raffles', nextId.toString()),
    {
      ...raffle,
      id: nextId.toString(),
      active: true,
    },
    { merge: true }
  );
};

export const editRaffle = async (raffleId, data) => {
  const raffle = updateRaffleProps(data);

  await updateDoc(doc(db, 'raffles', raffleId), {
    ...raffle,
  });
};
