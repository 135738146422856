import React from 'react';
import config from '../config';
import { Parallax } from 'react-scroll-parallax';
import Container from './Container';

function Footer() {
  return (
    <footer className='relative pt-24 pb-24 lg:pt-48 site-footer'>
      <div className='absolute hidden lg:block bottom-3 right-4'>
        <img src='/ybg-outline.png' alt={`${config.siteName} logo`} className='h-[300px]' />
      </div>
      <Container>
        <div className='space-y-6'>
          <p className='text-center'>
            {new Date().getFullYear()}{' '}
            <a
              href={config.twitter}
              target={'_blank'}
              rel='noreferrer'
              className='font-medium transition-opacity duration-200 text-theme-1 hover:opacity-50'
            >
              {config.siteName}
            </a>{' '}
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='inline h-2 px-2' fill='currentColor'>
              <path d='M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z' />
            </svg>{' '}
            An Algorand NFT Collection
          </p>
          <p className='text-center'>
            Website designed and built by{' '}
            <a
              href='https://twitter.com/MinnerAlgo'
              target={'_blank'}
              rel='noreferrer'
              className='font-medium transition-opacity duration-200 text-theme-1 hover:opacity-50'
            >
              @MinnerAlgo
            </a>
          </p>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
