import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { AlgoProvider } from '../hooks/useAlgo';
import { RafflesProvider } from '../hooks/useRaffles';
import { UserProvider } from '../hooks/useUser';

function Providers({ children }) {
  return (
    <ParallaxProvider>
      <UserProvider>
        <AlgoProvider>
          <RafflesProvider>{children}</RafflesProvider>
        </AlgoProvider>
      </UserProvider>
    </ParallaxProvider>
  );
}

export default Providers;
