const config = {
  siteName: 'YoungBruteGang',
  description:
    'An NFT collection of 199 YoungBrutes and 99 RebelBrutes on the Algorand blockchain. All unique in their own way! Come join the gang today!',
  domain: 'https://www.youngbrutegang.xyz',
  twitter: 'https://twitter.com/YoungBruteGang',
  discord: 'https://discord.com/invite/YoungBruteGang',
  nftExplorerYBG: 'https://www.nftexplorer.app/sellers/young-brute-gang',
  nftExplorerRBG: 'https://www.nftexplorer.app/sellers/rebel-brute-gang',
  randGalleryYBG: 'https://www.randgallery.com/collection/young-brute-gang',
  algoxnftYBG: 'https://algoxnft.com/collection/young-brute-gang',
};

export default config;
