import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import Link from 'next/link';
import clsx from 'clsx';

export default function Dropdown({ title, links }) {
  const router = useRouter();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [router?.pathname]);

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button
          onClick={() => setOpen(!open)}
          className={`hidden text-xl font-bold transition-opacity duration-150 hover:opacity-75 lg:flex items-center ${
            router.pathname == links[0].path ? 'text-primary-500' : 'text-gray-300'
          }`}
        >
          {title}
          <ChevronDownIcon className='w-5 h-5 ml-2 -mr-1 opacity-75' aria-hidden='true' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        show={open}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-[900] w-56 mt-2 origin-top-right shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-800 rounded-md'>
          <div className='py-1'>
            {links.map((link, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <Link href={link.path}>
                    <a className={clsx(active ? 'bg-gray-100 text-gray-300' : 'text-gray-200', 'block px-4 py-2 font-bold')}>{link.name}</a>
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
